@import "../../../data/styles.css";

.card {
	mix-blend-mode: normal;
	border-radius: 20px;
	outline: 2px solid #f4f4f5;
}

.card-container {
	padding: 30px;
	padding-bottom: 5px;
}

.card-header {
	display: flex;
	align-items: center;
}

.card-icon {
	font-size: 15px;
	color: var(--tertiary-color);
}

.card-title {
	color: var(--secondary-color);
	font-size: 14px;
	padding-left: 20px;
	font-weight: 650;
}

.card-body {
	padding-top: 20px;
	font-size: 15px;
}
